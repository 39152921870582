import { template as template_56e64f857df5486c8ab070ddca1b5ed3 } from "@ember/template-compiler";
const WelcomeHeader = template_56e64f857df5486c8ab070ddca1b5ed3(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
