import { template as template_b306af74cbde4bd8bdbe96a42289b750 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b306af74cbde4bd8bdbe96a42289b750(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
